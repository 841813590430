<template>
  <div class="main-info">
    <profile-main-info-header />
    <profile-main-info-body />
  </div>
</template>

<script>
export default {
  name: "ProfileMainInfo",
  components: {
    ProfileMainInfoHeader: () => import('@/components/template/profile/profile-main/ProfileMainInfoHeader'),
    ProfileMainInfoBody: () => import('@/components/template/profile/profile-main/ProfileMainInfoBody'),
  }
}
</script>

<style lang="scss" scoped>
.main-info {
  background: #FFFFFF;
  border-radius: 20px;
  padding: 40px;

  @media (max-width: 1200px) {
    padding: 30px 12px;
  }
}
</style>
